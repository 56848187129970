import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  /**
   *
   * @param req
   * @param next
   * expected output from api : {message:"", data:[] | {} }
   */
  constructor(
    private router: Router) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('called response interceptor', req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (Math.floor(err.status / 100) === 4) {
            // handle 400 errors
            // todo load a toast here with err.message - received from server
            if (err.status === 403) {
              //  Handling the GSTN API response
              if (err && err.error.message) {
                // this.toasterService.presentToast(err.error.message, 'Error', 'error');
              } else {
                // this.toasterService.presentToast(err.error, 'Error', 'error');
              }
            } else {
              // this.toasterService.presentToast(err.error.message, 'Error', 'error');
              console.log('err.error =', err.error, ';');
            }
          }
          if (Math.floor(err.status / 100) === 5) {
            console.log('err.error =', err.error, ';');
            const responseError = err.error;
            // if (responseError['data']) {
            //   // this.toasterService.presentToast(err.error.data.message, '', 'error');
            // } else if (ErrorCode.LOGGED_OUT === responseError['code']
            //   || ErrorCode.TOKEN_INVALID === responseError['code']
            //   || ErrorCode.TOKEN_EXPIRED === responseError['code']) {
            //   this.authService.initializeAppData();
            //   this.router.navigate(['welcome']);
            // } else if (ErrorCode.RESTAURANT_NOT_SERVING === responseError['code']
            //   || ErrorCode.NOT_SERVING_LOCATION === responseError['code']
            //   || ErrorCode.DELIVERY_OPERATION_OFF === responseError['code']) {
            // } else {
            //   this.toasterService.presentToast(err.error.message, '', 'error');
            // }
          }
          console.log(err);
          return throwError(err);
        }
      })
    );
  }
}
