export const UPI_PATTERN = /^(([^<>!#$%&\^\*()\[\]\\.,;:\s@'"?`~]+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+)+[a-zA-Z]{2,}))$/;
export function getModalConfig(type: string) {
    const CONFIG = {
        LINK_ACCOUNT_CONFIG: {
            windowClass: 'common-modal-css',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        CONFIRM_MODAL_CONFIG: {
          windowClass: 'confirmation-modal-css',
          backdrop: true,
          scrollable: false,
          centered: true
        },
    }
    return CONFIG[type];
}

export const ROUTES_STR = {
    paymentList: 'payment-list'
}

export const PAYMENT_LIST_ID = {
    WALLET: 'wallet',
    NETBANKING: 'net_banking',
    CARD: 'card',
    UPI: 'upi',
    COD: 'cod'
}

export const PAYMENT_LIST_ICON = {
    wallet:'icon-wallet',
    net_banking:'icon-globe',
    card:'icon-credit-card',
    upi:'icon-bhim-upi',
    cod:'icon-payment-cash'

    }


export const WALLET_PAYMENT_TYPE = {
    payTm: 'paytm',
    freeCharge: 'freecharge',
    lazyPay: 'lazypay',
    phonePe: 'phonepe',
    amazonPay: 'amazonpay',
    wallet: 'wallet'
}
export var isAddCardObj = {
    addNewCard: false
};
export const CURRENCY_CODE = {
    'usd' : '$',
    'inr' : '₹'
};
export const UPI_APP_LINKS = {
  android: {
    paytm: { package: "net.one97.paytm", type: "package" },
    googlepay: { package: "com.google.android.apps.nbu.paisa.user", type: "package" },
    phonepe: { package: "com.phonepe.app", type: "package" },
    bhimupi: { package: "upi://pay", type: "url" }
  },
  ios: {
    paytm: { package: "paytmmp://pay", type: "url" },
    googlepay: { package: "tez://upi/pay", type: "url" },
    phonepe: { package: "phonepe://pay", type: "url" },
    bhimupi: { package: "upi://pay", type: "url" }
  }
}
