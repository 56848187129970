import { NbListModule } from './module/nb-list/nb-list.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ROUTES_STR } from './constants/app-constants';

const routes: Routes = [
  {
    path: '',
    redirectTo: ROUTES_STR.paymentList,
    pathMatch: 'full'
  },
  {
    path: ROUTES_STR.paymentList,
    loadChildren: () => import('../app/module/payment-list/payment-list.module').then(m => m.PaymentListModule)
    // loadChildren : './module/payment-list/payment-list.module#PaymentListModule'
  },
  {
    path: 'nbList',
    loadChildren: () => import('../app/module/nb-list/nb-list.module').then(m => m.NbListModule)
    // loadChildren : './module/nb-list/nb-list.module#NbListModule'
  },
  { path: '**', redirectTo: ROUTES_STR.paymentList }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
