import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppData {
  static token$ = new BehaviorSubject(null);
  static token = '';
  static baseUrl = environment.baseUrl;
  static paymentBaseUrl = environment.paymentBaseUrl;
  static currency = 'inr';
  constructor() {
    AppData.token$.subscribe((res) => {
      if (res) {
        AppData.token = res;
      }
    });
  }

}
