import { AppData } from "./app-data.service";

export type URL_KEYS =
  'paymentView' | 'paymentCreate' |
  'sendOtp' | 'verifyOtp' | 'checkBalance' |
  'createPayment' | 'extra' | 'createCustomPayment';

const UrlMapping = {
  paymentView: { url: AppData.baseUrl + '/listAllPayments', showMsg: false, showLoading: true },
  paymentCreate: { url: AppData.baseUrl + 'paymentco/createPayment', showMsg: false, showLoading: true },
  sendOtp: { url: AppData.baseUrl + '/sendOtp', showMsg: false, showLoading: true },
  verifyOtp: { url: AppData.baseUrl + '/verifyOtp', showMsg: false, showLoading: true },
  checkBalance: { url: AppData.baseUrl + 'payment/paytm/checkBalance', showMsg: false, showLoading: true },
  extra: { url: AppData.baseUrl + '/extras', showMsg: false, showLoading: true },
  createPayment: { url: AppData.baseUrl + '/createPayment', showMsg: false, showLoading: true },
  createCustomPayment: { url: AppData.baseUrl + '/createCustomPayment', showMsg: false, showLoading: true },
  // cardPayment: { url: AppData.paymentBaseUrl + 'paytm/card', showMsg: false, showLoading: true },
  // nbPayment: { url: AppData.paymentBaseUrl + 'paytm/nb', showMsg: false, showLoading: true },
};

export class RequestUrl {
  static get(key: URL_KEYS): any {
    return UrlMapping[key];
  }
}
