import { IdsService } from './../../shared/services/ids.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { maxNumToBeAllowed } from 'src/app/shared/utils/common-functions';
import { LoaderService } from '../loader/service/loader.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  paytmImageUrl = 'https://sgp1.digitaloceanspaces.com/listing/yumzyx/payment/Paytm.jpg';
  @Input() mobileNumber;
  linkAnotherAccount = false;
  registrationForm: FormGroup;
  otpForm: FormGroup;
  isSubmitted = false;
  enableOtpSection = false;
  otpToken = '';
  errMsg = '';
  timer;
  enableResend = false;
  deviceId: string = '';
  originalMobileNumber: string;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private apiRequestService: ApiRequestService,
    private loaderService: LoaderService,
    private idsService: IdsService
  ) { }

  ngOnInit() {
    this.originalMobileNumber = this.mobileNumber.toString();
    this.deviceId = this.idsService.generate();
    this.registrationForm = this.fb.group({
      mobileNumber: [this.mobileNumber, [Validators.required, Validators.min(1111111111), Validators.max(9999999999)]]
    })

    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.min(111111), Validators.max(999999)]]
    })
  }

  onLinkAnotherAccount() {
    this.linkAnotherAccount = true;
    this.isSubmitted = false;
    this.registrationForm.controls.mobileNumber.setValue('');
    setTimeout(() => {
      document.getElementById('mobileNumber').focus();
    }, 50)
  }

  onKeypress(event, maxLength) {
    return maxNumToBeAllowed(event, maxLength);
  }

  async onProceed() {
    this.isSubmitted = true;

    if (this.registrationForm.valid) {
      const deviceId = '7f0f2126-320c-fff6-bea5-d73184d837aa';
      this.isSubmitted = false;
      if (this.linkAnotherAccount) {
        this.mobileNumber = this.registrationForm.controls.mobileNumber.value;
      }
      this.callSendOtpApi();
    }
  }

  callSendOtpApi() {
    const reqBody = {
      deviceId: this.deviceId,
      mobile: this.mobileNumber.toString(),
    };
    this.loaderService.showLoader();
    this.apiRequestService.post('sendOtp', reqBody, '', '?provider=paytm').then((res: HttpResponse<any>) => {
      console.log(res);
      this.otpToken = res['body']['data']['token'];
      this.enableOtpSection = true;
      this.setCountDownTimer();
      this.loaderService.hideLoader();
      setTimeout(() => {
        document.getElementById('otp-input').focus();
      }, 50)
    }).catch((err) => {
      console.log(err);
      this.loaderService.hideLoader();
    })
  }

  onVerifyOtp() {
    this.errMsg = '';
    const reqBody = {
      deviceId: this.deviceId,
      mobile: this.originalMobileNumber.toString(),
      walletMobile: this.mobileNumber.toString(),
      otp: this.otpForm.value.otp,
      token: this.otpToken,
    };
    if (this.otpForm.valid) {
      this.loaderService.showLoader();
      this.apiRequestService.post('verifyOtp', reqBody, '', '?provider=paytm').then((res) => {
        console.log(res);
        this.loaderService.hideLoader();
        this.activeModal.close(this.originalMobileNumber);
      }).catch((err: HttpErrorResponse) => {
        console.log(err);
        this.errMsg = err.error.message;
        this.loaderService.hideLoader();
      })
    }
  }

  onResend() {
    this.enableResend = false;
    this.callSendOtpApi();
  }

  setCountDownTimer() {
    const start = Date.now();
    const duration = 30;
    let diff;
    let minutes;
    let seconds;
    const interval = setInterval(() => {
      diff = duration - (((Date.now() - start) / 1000) | 0);

      minutes = (diff / 60) | 0;
      seconds = (diff % 60) | 0;

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      this.timer = minutes + ":" + seconds;

      if (diff <= 0) {
        this.enableResend = true;
        clearInterval(interval);
      }
    }, 1000)
  }

  onBack() {
    this.enableOtpSection = false;
  }

  checkValidationField(formName, fieldName: any) {
    if (this[formName].controls[fieldName].invalid && (this[formName].controls[fieldName].dirty ||
      this[formName].controls[fieldName].touched || this.isSubmitted)) {
      return true;
    }
    return false;
  }

}
