import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from '../loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  constructor() { }

  showLoader() {
    console.log('aaa')
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  hideLoader() {
    this.loaderSubject.next(<LoaderState>{ show: false });
  }
}
