import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppData } from './shared/services/app-data.service';
import { GlobalErrorHandler } from './shared/services/error-handler';
import { TokenInterceptor } from './shared/interceptor/token.interceptor';
import { ResponseInterceptor } from './shared/interceptor/response.interceptor';
import { NetworkInterceptor } from './shared/interceptor/network.interceptor';
import { ApiRequestService } from './shared/services/api-request.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './module/loader/loader.component';
import { RegistrationModule } from './module/registration/registration.module';
import { DirectiveModule } from './shared/directive/module/directive.module';
import { ConfirmationModalComponent } from './module/confirmation-modal/confirmation-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    ConfirmationModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    RegistrationModule,
    DirectiveModule,
  ],
  entryComponents:[ConfirmationModalComponent],
  providers: [{
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
  },
    AppData,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ResponseInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NetworkInterceptor,
    multi: true
  },
    ApiRequestService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
