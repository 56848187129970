import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  show = false;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService, private cdRef: ChangeDetectorRef) {
    console.log('oninit called');
  }

  ngOnInit() {
    console.log('triggered');
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        console.log(state);
        this.show = state.show;
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

export interface LoaderState {
  show: boolean;
}
