import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppData } from '../services/app-data.service';
import { map } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let tempToken = ''
    AppData.token$.subscribe(res => {
      console.log(res);
      tempToken = res;
    });
    const token = tempToken;
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIwMTc3YTU0MC1mM2Q2LTExZTktODMzZS00OTBiZmI0NTc0ZDQiLCJhcHAiOiJzdWdnZXN0aW9ucyIsImRldmljZUlkIjoid2ViLXdlYi04M2FiOWYwYi01YTBjLTM0ZGUtMDNjZi02MjA2ODAyOWQ4OTEiLCJzZWNyZXQiOiJuOHZhUmdQNVZrc1d2TmJnekR6emo2RDVYRmNSSDNUciIsIm1vYmlsZSI6Ijc3Nzc3Nzc3NzciLCJpYXQiOjE2MTczNjE2MTN9.suBILRAQ2S-iqlYKntSDDaHqfIsxUlGGoFSBgsOjZco';
    let headers = req.headers;
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    // headers = headers.append('version', '3000000');
    if (token) {
      headers = headers.append('x-access-token', token);
    }
    console.log('====>', headers);
    req = req.clone({ headers });
    // return next.handle(req);
    return next.handle(req)
      .pipe(map(event => {
        if (event instanceof HttpResponse) {
          console.log(event);
          if (event.headers.get('x-access-token')) {
            AppData.token$.next(event.headers.get('x-access-token'));
          }
        }
        return event;
      }));
  }
}
